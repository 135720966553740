<template>
    <v-container style="margin-bottom: 50px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                        <div>
                            <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-1" v-show="display === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="rounded-l" outlined>
                    <v-card-text class="p-2">
                        <v-container>
                            <v-row>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <h6 class="blue-lcd mr-5 mt-3">カルテ番号</h6>
                                        <v-text-field
                                            solo
                                            label="カルテ番号"
                                            persistent-hint
                                            required
                                            v-model="card_number"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </div>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="5" lg="5" class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <h6 class="blue-lcd mr-5 mt-3">Free Text</h6>
                                        <v-text-field
                                            solo
                                            label="Free Text"
                                            persistent-hint
                                            required
                                            v-model="free_text"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </div>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="3" class="ma-0 pa-0">
                                    <v-btn class="border-12 text-white p-4 mr-3" color="secondary" @click="getCardNumber()">{{ $t('labels.search') }}</v-btn>
                                    <v-btn class="border-12 p-4" color="#fff" @click="clearFilter()">{{ $t('labels.clear') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" style="border-top: 3px solid #e83e8c;">
                    <v-card-title>
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-data-table
                            :headers="headers"
                            :items="cards"
                            :options.sync="options"
                            :loading="loadingDatatable"
                            loading-text="Please wait, retrieving data"
                            :server-items-length="totalItems"
                            :page.sync="pagination"
                            page-count="10"
                            class="elevation-1"
                            height="450"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :item-class="tr_datatable"
                            @click:row="showDetail"
                        >
                            <template v-slot:[`header.id`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.name`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.rubi`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.reservation_at`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.created_at`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.updated_at`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-1" v-show="displayItem === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="elevation-6 rounded-l" style="border-top: 3px solid #e83e8c;">
                    <v-card-title class="p-1">                        
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="close()"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="p-4">
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="5" class="pt-0 pb-0">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text>
                                        <h6>
                                            <b>{{ name }}</b> | {{ ruby }}
                                        </h6>
                                        <p class="mb-0 pb-0">  {{date_of_birth }}  <b>|</b> {{age}} <b>|</b> {{gender}} </p>
                                        <v-list dense>
                                            <v-list-item class="pl-0">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-home</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{address}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="pl-0">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-phone</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{mobile}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-tabs
                                            color="#e83e8c"
                                            v-model="tab"
                                            class="pl-0 pr-0 ml-0 mr-0"
                                        >
                                            <v-tab href="#tab-profile">診療歴</v-tab>
                                            <v-tab href="#tab-image">症例写真</v-tab>
                                            <v-tab href="#tab-note">書類</v-tab>
                                            <v-tab href="#tab-question">問診表</v-tab>
                                            <v-tab href="#tab-history-buy">購入履歴</v-tab>
                                            <v-tab href="#tab-simpan">予約</v-tab>
                                        </v-tabs>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                value="tab-profile"
                                            >
                                                <v-row class="my-1">
                                                    <v-col cols="12">
                                                        <v-btn
                                                            small
                                                            color="success"
                                                            class="py-4 border-12"
                                                            @click="dialogRecord = true"
                                                        >
                                                            <v-icon>
                                                                mdi-plus
                                                            </v-icon>
                                                            新規作成
                                                        </v-btn>
                                                    </v-col>
                                                    <v-dialog
                                                        v-model="dialogRecord"
                                                        max-width="400px"
                                                        persistent
                                                    >
                                                        <v-card style="overflow: hidden;">
                                                            <v-card-title class="p-1">                        
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    class="mx-2"
                                                                    fab
                                                                    x-small
                                                                    color="#e83e8c;"
                                                                    @click="dialogRecord = false"
                                                                >
                                                                    <v-icon dark>
                                                                        mdi-close
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-container class="fill-height">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <h6>
                                                                                診療歴を新規作成しますか？
                                                                            </h6>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-card-text>
                                                            <v-card-actions class="text-center">
                                                                <v-row justify="center">
                                                                    <v-col cols="12" xs="12" sm="3" md="3">
                                                                        <v-btn block class="rounded-l p-3" @click="dialogRecord = false">閉じる</v-btn>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="3" md="3">
                                                                        <v-btn block class="rounded-l text-white p-3" color="#003871" @click="insertRecord()">新規作成</v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-row>
                                                <v-simple-table
                                                    fixed-header
                                                    height="445"
                                                    dense
                                                    class="p-2"
                                                >
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr style="border: 1px solid rgba(0, 0, 0, 0.6);">
                                                                <th class="text-left">
                                                                    Date
                                                                </th>
                                                                <th class="text-left">
                                                                    Doctor
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="history_lists.length > 0">
                                                            <tr
                                                            v-for="(item) in history_lists"
                                                            :key="item.date"
                                                            style="border: 1px solid rgba(0, 0, 0, 0.6);"
                                                            :class="item.date === color_default ? 'primary' : ''"
                                                            @click="rowHistory(item)"
                                                            >
                                                                <td>{{ item.date }}</td>
                                                                <td>{{ item.doctor }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr style="border: 1px solid rgba(0, 0, 0, 0.6);">
                                                                <td colspan="3" class="text-center">
                                                                    Data not available
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-tab-item>
                                            <v-tab-item
                                                value="tab-image"
                                            >
                                                <v-row class="my-1">
                                                    <v-col cols="12">
                                                        <v-btn
                                                            small
                                                            color="success"
                                                            class="py-4 border-12"
                                                            @click="dialog_image = true"
                                                        >
                                                            <v-icon>
                                                                mdi-plus
                                                            </v-icon>
                                                            画像を追加
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-card height="460" outlined class="fill-height">
                                                    <v-card-text>   
                                                        <v-container class="fill-height">
                                                            <v-row>
                                                                <v-col cols="12">
                                                                    <v-dialog
                                                                        v-model="dialog_image"
                                                                        max-width="900px"
                                                                        persistent
                                                                    >
                                                                        <v-card>
                                                                            <v-card-title class="p-1">                        
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn
                                                                                    class="mx-2"
                                                                                    fab
                                                                                    x-small
                                                                                    color="#e83e8c;"
                                                                                    @click="dialog_image = false"
                                                                                >
                                                                                    <v-icon dark>
                                                                                        mdi-close
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container class="fill-height">
                                                                                    <v-row>

                                                                                        <div class="col-md-2 thumbnail" v-for="(gambar, key) in raw_images" :key="key">
                                                                                            <img :src="gambar" class="img" style="width: 100%; border-radius:6px; object-fit: contain;">
                                                                                            <button class="btn btn-danger" v-on:click.prevent="hapus(key)" style="margin-top: 5px;padding:5px;">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div class="col-md-2">
                                                                                            <div style="padding: 40px; text-align:center; border: dashed 2px #dddddd; cursor: pointer; width:100%" @click="dialog_crop = true">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </v-row>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions class="text-center">
                                                                                <v-row justify="center">
                                                                                    <v-col cols="12" xs="12" sm="3" md="4">
                                                                                        <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveCaseImages()">{{$t('buttons.save')}}</v-btn>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                    <v-dialog
                                                                        v-model="dialog_crop"
                                                                        max-width="600px"
                                                                        persistent
                                                                    >
                                                                        <v-card>
                                                                            <v-card-title class="p-1">                        
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn
                                                                                    class="mx-2"
                                                                                    fab
                                                                                    x-small
                                                                                    color="#e83e8c;"
                                                                                    @click="dialog_crop = false"
                                                                                >
                                                                                    <v-icon dark>
                                                                                        mdi-close
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container class="fill-height">
                                                                                    <v-row>
                                                                                        <v-col cols="12" xs="12" sm="12" md="12">
                                                                                            <h6>
                                                                                                写真を選択
                                                                                            </h6>

                                                                                            <div v-if="imgSrc" style="width: auto; height:500px; border: 1px solid gray; display: inline-block;">
                                                                                                <vue-cropper
                                                                                                    ref='cropper'
                                                                                                    :guides="true"
                                                                                                    :view-mode="2"
                                                                                                    drag-mode="crop"
                                                                                                    :auto-crop-area="0.5"
                                                                                                    :min-container-width="250"
                                                                                                    :min-container-height="180"
                                                                                                    :background="true"
                                                                                                    :rotatable="true"
                                                                                                    :src="imgSrc"
                                                                                                    :aspectRatio= "1/1"
                                                                                                    :img-style="{ 'width': 'auto', 'height': '500px' }">
                                                                                                </vue-cropper>
                                                                                            </div>

                                                                                            <div v-else>
                                                                                                <input type="file" name="image" id="image" @change="setImage" class="form-control" accept="image/*" multiple>
                                                                                            </div>
                                                                                        </v-col>
                                                                                    </v-row> 
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions class="text-center">
                                                                                <v-row justify="center">
                                                                                    <v-col cols="12" xs="12" sm="3" md="4">
                                                                                        <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveImage()">{{$t('buttons.save')}}</v-btn>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-for="item in images"
                                                                :key="item.date">
                                                                <v-col cols="12">
                                                                    <v-card outlined >
                                                                        <v-card-text style="overflow: inherit;">
                                                                            <v-row>
                                                                                <v-col cols="12">
                                                                                    <h6 class="blue-lcd mr-5 mt-3">{{item.date}}</h6>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col
                                                                                    v-for="(img, index) in item.case_images"
                                                                                    :key="img"
                                                                                    class="d-flex child-flex"
                                                                                    cols="3"
                                                                                >
                                                                                    <v-img
                                                                                        :src="img"
                                                                                        :lazy-src="img"
                                                                                        aspect-ratio="1"
                                                                                        class="grey lighten-2"
                                                                                        height="100"
                                                                                        width="100"
                                                                                        style="overflow: inherit;"
                                                                                        @click="[overlay = true, getFullImage(img, item.case_images, index)]"
                                                                                    >
                                                                                    </v-img>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                    <v-overlay v-model="overlay"></v-overlay>
                                                                    <v-dialog
                                                                        v-model="dialog_full_image"
                                                                        max-width="900px"
                                                                        persistent
                                                                    >
                                                                        <v-card>
                                                                            <v-card-title class="p-1">                        
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn
                                                                                    class="mx-2"
                                                                                    fab
                                                                                    x-small
                                                                                    color="#e83e8c;"
                                                                                    @click="dialog_full_image = false"
                                                                                >
                                                                                    <v-icon dark>
                                                                                        mdi-close
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-row align="center" justify="center">
                                                                                        <v-col cols="2" class="text-center">
                                                                                            <v-btn
                                                                                                class="mx-2"
                                                                                                fab
                                                                                                x-small
                                                                                                color="#e83e8c;"
                                                                                                @click="prevImage(image_full_key)"
                                                                                            >
                                                                                                <v-icon dark>
                                                                                                    mdi-chevron-left
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                            <v-btn
                                                                                                class="mx-2"
                                                                                                fab
                                                                                                x-small
                                                                                                color="#e83e8c;"
                                                                                                @click="nextImage(image_full_key)"
                                                                                            >
                                                                                                <v-icon dark>
                                                                                                    mdi-chevron-right
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row>
                                                                                        <v-col
                                                                                        cols="12"
                                                                                        >
                                                                                            <v-img
                                                                                                :src="image_full"
                                                                                                :lazy-src="image_full"
                                                                                                class="grey lighten-2 pb-0"
                                                                                                height="500"
                                                                                                width="900"
                                                                                                style="overflow: inherit;padding-bottom: 0;"
                                                                                            >
                                                                                            </v-img>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-col>
                                                                <v-divider></v-divider>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                                    
                                            </v-tab-item>
                                            <v-tab-item
                                                value="tab-note"
                                            >
                                                <v-row class="my-1">
                                                    <v-col cols="12">
                                                        <v-btn
                                                            small
                                                            color="success"
                                                            class="py-4 border-12"
                                                            @click="dialog_upload_document = true"
                                                        >
                                                            <v-icon>
                                                                mdi-upload
                                                            </v-icon>
                                                            アップロード
                                                        </v-btn>
                                                        <v-dialog
                                                            v-model="dialog_upload_document"
                                                            max-width="600px"
                                                            persistent
                                                        >
                                                            <v-card>
                                                                <v-card-title class="p-1">                        
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        class="mx-2"
                                                                        fab
                                                                        x-small
                                                                        color="#e83e8c;"
                                                                        @click="dialog_upload_document = false"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-close
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <v-container class="fill-height">
                                                                        <v-row>
                                                                            <v-col cols="12" xs="12" sm="12" md="12">
                                                                                <h6>
                                                                                    ファイルを選択
                                                                                </h6>
                                                                                <div>
                                                                                    <input type="file" name="image" id="image" @change="setFileDocument" class="form-control" accept="*">
                                                                                </div>
                                                                            </v-col>
                                                                        </v-row> 
                                                                    </v-container>
                                                                </v-card-text>
                                                                <v-card-actions class="text-center">
                                                                    <v-row justify="center">
                                                                        <v-col cols="12" xs="12" sm="3" md="4">
                                                                            <v-btn block class="rounded-l text-white p-4" color="#003871" @click="uploadDocument()">{{$t('buttons.save')}}</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                    </v-col>
                                                </v-row>
                                                <v-simple-table
                                                    fixed-header
                                                    height="445"
                                                    dense
                                                    class="p-2"
                                                >
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr style="border: 1px solid rgba(0, 0, 0, 0.6);">
                                                                <th class="text-left">
                                                                    Filename
                                                                </th>
                                                                <th class="text-left">
                                                                    Created at
                                                                </th>
                                                                <th class="text-left">
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="file_documents.length > 0">
                                                            <tr
                                                            v-for="(item, i) in file_documents"
                                                            :key="item.created_at"
                                                            style="border: 1px solid rgba(0, 0, 0, 0.6);"
                                                            :class="item.created_at === color_default ? 'primary' : ''"
                                                            @click="rowDocument(item)"
                                                            >
                                                                <td>{{ item.file_name }}</td>
                                                                <td>{{ item.created_at }}</td>
                                                                <td>
                                                                    <v-btn
                                                                        fab
                                                                        x-small
                                                                        color="red"
                                                                        class="text-white"
                                                                        @click="[dialogDeleteDocument(i, item), dialog_delete_document = true]"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr style="border: 1px solid rgba(0, 0, 0, 0.6);">
                                                                <td colspan="3" class="text-center">
                                                                    Data not available
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                                <v-dialog
                                                    v-model="dialog_delete_document"
                                                    max-width="400px"
                                                    persistent
                                                >
                                                    <v-card style="overflow: hidden;">
                                                        <v-card-title class="p-1">                        
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                class="mx-2"
                                                                fab
                                                                x-small
                                                                color="#e83e8c;"
                                                                @click="dialog_delete_document = false"
                                                            >
                                                                <v-icon dark>
                                                                    mdi-close
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12">
                                                                        <h6 class="text-center">
                                                                            {{ document ? document.file_name : '' }}
                                                                        </h6>
                                                                        <p class="text-center">
                                                                            このファイルを削除してよろしいですか？
                                                                        </p>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card-text>
                                                        <v-card-actions class="text-center">
                                                            <v-row justify="center">
                                                                <v-col cols="12" xs="12" sm="3" md="3">
                                                                    <v-btn block class="rounded-l p-3" @click="dialog_delete_document = false">閉じる</v-btn>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="3">
                                                                    <v-btn block class="rounded-l text-white p-3" color="#003871" @click="deleteDocument()">削除</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-tab-item>
                                            <v-tab-item
                                                value="tab-question"
                                            >
                                            </v-tab-item>
                                            <v-tab-item
                                                value="tab-history-buy"
                                            >
                                                <v-card 
                                                height="530"
                                                outlined
                                                >
                                                    <v-card-text class="p-1">
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col cols="12" class="p-1">
                                                                    <v-card outlined class="border-12 pl-3 pr-3 pt-2 pb-0 mt-3" v-for="(detail, i) in prescriptions" :key="i">
                                                                        <v-card-text class="p-2" style="overflow: hidden;">
                                                                            <v-row class="mt-1" v-if="detail.medicines.length > 0">
                                                                                <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12" v-if="detail">
                                                                                    <table>
                                                                                        <tr  v-for="(medicine, i) in detail.medicines" :key="i">
                                                                                            <td style="width: 10%;">{{$t('labels.medicine')}}</td>
                                                                                            <td style="width: 25%;">{{medicine.date}}</td>
                                                                                            <td style="width: 50%;">{{medicine.name}}</td>
                                                                                            <td style="width: 10%;">{{medicine.amount}}</td>
                                                                                            <td style="width: 5%;">{{medicine.unit}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row class="mb-1" v-if="detail.items.length > 0">
                                                                                <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12" v-if="detail">
                                                                                    <table>
                                                                                        <tr v-for="(item, i) in detail.items" :key="i">
                                                                                            <td style="width: 10%;">{{$t('labels.item')}}</td>
                                                                                            <td style="width: 25%;">{{item.date}}</td>
                                                                                            <td style="width: 50%;">{{item.name}}</td>
                                                                                            <td style="width: 10%;">{{item.amount}}</td>
                                                                                            <td style="width: 5%;">{{item.unit}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </v-col>
                                                                            </v-row> 
                                                                            <v-row class="mt-3">
                                                                                <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12 mt-3">
                                                                                    <div class="d-flex">
                                                                                        <v-spacer></v-spacer>
                                                                                        <p>金額 {{ detail ? $store.getters.convertToCurrencyUs(detail.price) : '-' }} 円</p>
                                                                                    </div>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item
                                                value="tab-simpan"
                                            >
                                                <v-simple-table
                                                    fixed-header
                                                    height="517"
                                                    dense
                                                    class="p-2"
                                                >
                                                    <template v-slot:default>
                                                        <tbody v-if="reservations.length > 0">
                                                            <tr
                                                            v-for="(item, i) in reservations"
                                                            :key="item.id"
                                                            style="border: 1px solid rgba(0, 0, 0, 0.6);"
                                                            >
                                                                <td>{{ item.start_datetime.substr(0,10) }}</td>
                                                                <td v-if="item.start_datetime === item.end_datetime">
                                                                    {{  item.start_datetime.toString().substring(11,16) }}
                                                                </td>
                                                                <td v-else>{{  item.start_datetime.toString().substring(11,16) }} - {{ item.end_datetime.toString().substring(11,16) }}</td>
                                                                <td>{{ item.treatment ? item.treatment.name : '' }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr style="border: 1px solid rgba(0, 0, 0, 0.6);">
                                                                <td colspan="3" class="text-center">
                                                                    Data not available
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="7" class="pt-0 pb-0">
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6" :md="md_comment" class="pt-0 pb-0" v-show="comment_show">
                                            <v-expand-x-transition mode="in-out">
                                                <v-card class="rounded-l" outlined transition="slide-x-reverse-transition">
                                                    <v-card-title class="mb-0 pb-0">
                                                        <v-row class="mb-0 p-1">
                                                            <v-col cols="12" class="mb-0 p-1">
                                                                <div class="d-flex">
                                                                    <h6>所見</h6>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        class="mx-2"
                                                                        fab
                                                                        x-small
                                                                        color="#e83e8c;"
                                                                        @click="collapseComment()"
                                                                    >
                                                                        <v-icon dark>
                                                                        {{icon_comment}}
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </v-col>
                                                            <v-divider></v-divider>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="d-flex">
                                                            <v-btn-toggle
                                                                v-model="toggle_multiple"
                                                                dense
                                                                multiple
                                                            >
                                                                <v-btn @click="changeStyle('span-b')">
                                                                    <v-icon>mdi-format-bold</v-icon>
                                                                </v-btn>
                                                                <v-btn @click="changeStyle('span-i')">
                                                                    <v-icon>mdi-format-italic</v-icon>
                                                                </v-btn>
                                                                <v-btn @click="changeStyle('span-u')">
                                                                    <v-icon>mdi-format-underline</v-icon>
                                                                </v-btn>
                                                                
                                                            </v-btn-toggle>
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                small
                                                                color="secondary"
                                                                class="py-4 border-12"
                                                                @click="saveComment()"
                                                            >
                                                                <v-icon>
                                                                    mdi-content-save
                                                                </v-icon>
                                                                保存
                                                            </v-btn>
                                                        </div>
                                                        <!-- <div contenteditable="true" class="editable" id="editable">
                                                            
                                                        </div> -->
                                                        <v-textarea
                                                        solo
                                                        name="input-12-4"
                                                        height="605"
                                                        v-model="comment_text"
                                                        ></v-textarea>
                                                    </v-card-text>
                                                </v-card>
                                            </v-expand-x-transition>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" :md="md_order" class="pt-0 pb-0" v-show="comment_order">
                                            <v-expand-x-transition mode="in-out">
                                                <v-card class="rounded-l" outlined transition="slide-x-reverse-transition">
                                                    <v-card-title class="mb-0 pb-0">
                                                        <v-row class="mb-0 p-1">
                                                            <v-col cols="12" class="mb-0 p-1">
                                                                <div class="d-flex">
                                                                    <v-btn
                                                                        class="mx-2"
                                                                        fab
                                                                        x-small
                                                                        color="#e83e8c;"
                                                                        @click="collapseOrder()"
                                                                    >
                                                                        <v-icon dark>
                                                                        {{icon_order}}
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    <v-spacer></v-spacer>
                                                                    <h6>オーダー</h6>
                                                                    
                                                                </div>
                                                            </v-col>
                                                            <v-divider></v-divider>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="d-flex">
                                                            <v-spacer></v-spacer>
                                                            <v-btn-toggle
                                                                v-model="toggle_multiple"
                                                                dense
                                                                multiple
                                                            >
                                                                <v-btn @click="[getTreatment(), dialog_treatment = true]">
                                                                    施術
                                                                </v-btn>
                                                                <v-btn @click="[getMedicine(), dialog_medicine = true]">
                                                                    薬剤
                                                                </v-btn>
                                                                <v-btn @click="[getItem(), dialog_item = true]">
                                                                    物品
                                                                </v-btn>
                                                                
                                                            </v-btn-toggle>
                                                        </div>
                                                        <v-dialog
                                                            v-model="dialog_treatment"
                                                            max-width="400px"
                                                            persistent
                                                        >
                                                            <v-card style="overflow: hidden;">
                                                                <v-card-title class="p-1">                        
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        class="mx-2"
                                                                        fab
                                                                        x-small
                                                                        color="#e83e8c;"
                                                                        @click="dialog_treatment = false"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-close
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <v-container class="fill-height">
                                                                        <v-row>
                                                                            <v-col cols="12">
                                                                                <v-autocomplete
                                                                                    dense
                                                                                    solo
                                                                                    clearable
                                                                                    v-model="treatment"
                                                                                    :items="treatments"
                                                                                    item-value="id"
                                                                                    :item-text="item => item.id +' - '+ item.name +' - '+ item.type"
                                                                                    class="ma-0 pa-0 border-12"
                                                                                    hide-details=true
                                                                                    height="50"
                                                                                ></v-autocomplete> 
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>
                                                                <v-card-actions class="text-center">
                                                                    <v-row justify="center">
                                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                                            <v-btn block class="rounded-l p-3" @click="dialog_treatment = false">閉じる</v-btn>
                                                                        </v-col>
                                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                                            <v-btn block class="rounded-l text-white p-3" color="#003871">追加</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-dialog
                                                            v-model="dialog_medicine"
                                                            max-width="400px"
                                                            persistent
                                                        >
                                                            <v-card style="overflow: hidden;">
                                                                <v-card-title class="p-1">                        
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        class="mx-2"
                                                                        fab
                                                                        x-small
                                                                        color="#e83e8c;"
                                                                        @click="dialog_medicine = false"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-close
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <v-container class="fill-height">
                                                                        <v-row>
                                                                            <v-col cols="12">
                                                                                <v-autocomplete
                                                                                    dense
                                                                                    solo
                                                                                    clearable
                                                                                    v-model="medicine"
                                                                                    :items="medicines"
                                                                                    item-value="id"
                                                                                    :item-text="item => item.id +' - '+ item.name +' - '+ item.type"
                                                                                    class="ma-0 pa-0 border-12"
                                                                                    hide-details=true
                                                                                    height="50"
                                                                                    return-object
                                                                                ></v-autocomplete> 
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>
                                                                <v-card-actions class="text-center">
                                                                    <v-row justify="center">
                                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                                            <v-btn block class="rounded-l p-3" @click="dialog_medicine = false">閉じる</v-btn>
                                                                        </v-col>
                                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                                            <v-btn block class="rounded-l text-white p-3" color="#003871" @click="saveProduct()">追加</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-dialog
                                                            v-model="dialog_item"
                                                            max-width="400px"
                                                            persistent
                                                        >
                                                            <v-card style="overflow: hidden;">
                                                                <v-card-title class="p-1">                        
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        class="mx-2"
                                                                        fab
                                                                        x-small
                                                                        color="#e83e8c;"
                                                                        @click="dialog_item = false"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-close
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <v-container class="fill-height">
                                                                        <v-row>
                                                                            <v-col cols="12">
                                                                                <v-autocomplete
                                                                                    dense
                                                                                    solo
                                                                                    clearable
                                                                                    v-model="item"
                                                                                    :items="items"
                                                                                    item-value="id"
                                                                                    :item-text="item => item.id +' - '+ item.name"
                                                                                    class="ma-0 pa-0 border-12"
                                                                                    hide-details=true
                                                                                    height="50"
                                                                                ></v-autocomplete> 
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>
                                                                <v-card-actions class="text-center">
                                                                    <v-row justify="center">
                                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                                            <v-btn block class="rounded-l p-3" @click="dialog_item = false">閉じる</v-btn>
                                                                        </v-col>
                                                                        <v-col cols="12" xs="12" sm="3" md="3">
                                                                            <v-btn block class="rounded-l text-white p-3" color="#003871">追加</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-card 
                                                        height="635"
                                                        outlined
                                                        >
                                                            <v-card-text class="p-1">
                                                                <v-container fluid>
                                                                    <v-row>
                                                                        <v-col cols="12" class="p-1">
                                                                            <v-card outlined class="border-12 pl-3 pr-3 pt-2 pb-0 mt-3" v-for="(detail, i) in prescriptions" :key="i">
                                                                                <v-card-title class="p-1">                        
                                                                                    <v-spacer></v-spacer>
                                                                                    <v-btn
                                                                                        fab
                                                                                        x-small
                                                                                        color="red"
                                                                                        class="text-white"
                                                                                        @click="[dialogDeletePrescription(i, detail), dialog_delete_prescription = true]"
                                                                                    >
                                                                                        <v-icon dark>
                                                                                            mdi-delete
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </v-card-title>
                                                                                <v-row class="mt-1" v-if="detail.course">
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p class="pink-heading pb-0 mb-0 mr-2">{{$t('labels.subscriptionCourses')}} : </p>
                                                                                    </v-col>
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p>{{ detail ? detail.course : '-' }}</p>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="mt-1" v-if="detail.single">
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p class="pink-heading pb-0 mb-0 mr-2">単品 : </p>
                                                                                    </v-col>
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p class="pb-0 mb-0">{{ detail ? detail.single : '-' }}</p>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="mt-1" v-if="detail.medicines.length > 0">
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p class="pink-heading pb-0 mb-0">{{$t('labels.medicine')}} : </p>
                                                                                    </v-col>
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12" v-if="detail">

                                                                                        <v-list dense class="pl-0 pr-0 pt-0 pb-0">
                                                                                            <v-list-item-group
                                                                                                color="primary"
                                                                                            >
                                                                                                <v-list-item
                                                                                                v-for="(medicine, i) in detail.medicines" :key="i"
                                                                                                class="pt-0 pb-0 mb-0 mt-0 pl-0 pr-0"
                                                                                                >
                                                                                                <v-list-item-content>
                                                                                                    <v-list-item-title>
                                                                                                        <div class="d-flex">
                                                                                                            <p v-if="medicine.date" class="mr-1 pb-0 mb-0">{{medicine.date}}</p>
                                                                                                            <p class="mr-1 pb-0 mb-0">{{medicine.name}}</p>
                                                                                                            <p class="mr-1 pb-0 mb-0">{{medicine.amount}}</p>
                                                                                                            <p class="pb-0">{{medicine.unit}}</p>
                                                                                                        </div>
                                                                                                    </v-list-item-title>
                                                                                                        
                                                                                                </v-list-item-content>
                                                                                                </v-list-item>
                                                                                            </v-list-item-group>
                                                                                        </v-list>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row class="mb-1" v-if="detail.items.length > 0">
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p class="pink-heading pb-0 mb-0">{{$t('labels.item')}} : </p>
                                                                                    </v-col>
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12" v-if="detail">
                                                                                        <v-list dense class="pt-0 pb-0">
                                                                                            <v-list-item-group
                                                                                                color="primary"
                                                                                            >
                                                                                                <v-list-item
                                                                                                v-for="(item, i) in detail.items" :key="i"
                                                                                                class="pt-0 pb-0 mb-0 mt-0 pl-0 pr-0"
                                                                                                >
                                                                                                <v-list-item-content>
                                                                                                    <v-list-item-title>
                                                                                                        <div class="d-flex">
                                                                                                            <p v-if="item.date" class="mr-1 mb-0 pb-0">{{item.date}}</p>
                                                                                                            <p class="mr-1 mb-0 pb-0">{{item.name}}</p>
                                                                                                            <p class="mr-1 mb-0 pb-0">{{item.amount}}</p>
                                                                                                            <p class="mr-1 mb-0 pb-0">{{item.unit}}</p>
                                                                                                        </div>
                                                                                                    </v-list-item-title>
                                                                                                </v-list-item-content>
                                                                                                </v-list-item>
                                                                                            </v-list-item-group>
                                                                                        </v-list>
                                                                                    </v-col>
                                                                                </v-row> 
                                                                                <v-row>
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p class="pink-heading pb-0 mb-0" style="font-size:0.9rem">{{$t('labels.price')}} : </p>
                                                                                    </v-col>
                                                                                    <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                        <p>{{ detail ? $store.getters.convertToCurrencyUs(detail.price) : '-' }} 円</p>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-card>
                                                                            <v-dialog
                                                                                v-model="dialog_delete_prescription"
                                                                                max-width="400px"
                                                                                persistent
                                                                            >
                                                                                <v-card style="overflow: hidden;">
                                                                                    <v-card-title class="p-1">                        
                                                                                        <v-spacer></v-spacer>
                                                                                        <v-btn
                                                                                            class="mx-2"
                                                                                            fab
                                                                                            x-small
                                                                                            color="#e83e8c;"
                                                                                            @click="dialog_delete_prescription = false"
                                                                                        >
                                                                                            <v-icon dark>
                                                                                                mdi-close
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </v-card-title>
                                                                                    <v-card-text>
                                                                                        <v-container>
                                                                                            <v-row>
                                                                                                <v-col cols="12">
                                                                                                    <h6 class="text-center">
                                                                                                        {{ course }}
                                                                                                    </h6>
                                                                                                    <p class="text-center">
                                                                                                        この商品を購入から削除しますか？
                                                                                                    </p>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-container>
                                                                                    </v-card-text>
                                                                                    <v-card-actions class="text-center">
                                                                                        <v-row justify="center">
                                                                                            <v-col cols="12" xs="12" sm="3" md="3">
                                                                                                <v-btn block class="rounded-l p-3" @click="dialog_delete_prescription = false">閉じる</v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="12" xs="12" sm="3" md="3">
                                                                                                <v-btn block class="rounded-l text-white p-3" color="#003871" @click="deletePrescription()">削除</v-btn>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-card-actions>
                                                                                </v-card>
                                                                            </v-dialog>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-card-text>
                                                </v-card>
                                            </v-expand-x-transition>
                                        </v-col>
                                    </v-row> 
                                </v-container>  
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { Icon } from '@iconify/vue2';
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {backendAdmin} from "../../backend-api/backendAdmin"

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_}',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        Icon,
        ValidationProvider,
        ValidationObserver,
        VueCropper
    },
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'ホーム',
                disabled: false,
                href: '/staff/dashboard',
                },
                {
                text: 'カルテ',
                disabled: true,
                href: 'breadcrumbs_link_2',
                }
            ],
            show: false,
            cards: [],
            loading: false,
            firstLoad: true,
            theme: {
                isDark: false
            },
            items: [{name: 'a'}, {name: 'b'}, {name: 'c'}, {name: 'd'}],
            searchItem: '',
            loadingDatatable: false,
            headers:[
                { text: 'カルテ番号', value: 'id', sortable: false},
                { text: '名前', value: 'name', sortable: false },
                { text: '予約日時', value: 'reservation_at', sortable: false },
                { text: '作成日時', value: 'created_at', sortable: false },
                { text: '更新日時', value: 'updated_at', sortable: false }
            ], 
            detailItem: null,
            display: 'block',
            displayItem: 'none',
            card_number: '',
            free_text: '',
            options: {},
            totalItems: 10,
            pagination: 1,
            sortBy: 'desc',
            sortName: 'id',
            date_of_birth: '',
            name: '',
            ruby: '',
            age: '',
            gender: '',
            mobile: '',
            address: '',
            tab: null,
            toggle_multiple: [],
            history_lists: [],
            dialog_image: false,
            raw_images: [],
            images: [],
            imgSrc: '',
            cropImg: '',
            dialog_crop: false,
            overlay: false,
            dialog_full_image: false,
            image_full: '',
            image_full_key: '',
            image_fulls: [],
            comment_text: '',
            color_default: '',
            rowDetailHistory: null,
            dialogRecord:false,
            md_comment: 6,
            md_order: 6,
            comment_show: true,
            comment_order: true,
            icon_comment:'mdi-arrow-collapse-right',
            icon_order:'mdi-arrow-collapse-left',
            treatment: '',
            treatments: [],
            dialog_treatment: false,
            medicine: '',
            medicines: [],
            dialog_medicine: false,
            item: '',
            items: [],
            dialog_item: false,
            prescriptions: [],
            course: '',
            dialog_delete_prescription: false,
            indexCourse: '',
            file_documents: [],
            dialog_upload_document: false,
            file_name: '',
            file_data: '',
            dialog_delete_document: false,
            document: null,
            reservations: []
        }
    },
    mounted() {
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        close(){
            this.display = 'block'
            this.displayItem = 'none'
            this.getCardNumber(this.pagination,10)
        },
        collapseComment(){
            if (this.icon_comment == 'mdi-arrow-collapse-right') {
                this.icon_comment = 'mdi-arrow-collapse-left'
                this.md_comment = this.md_comment == 6 ? 12 : 6
                this.comment_order = false
            } else{
                this.icon_comment = 'mdi-arrow-collapse-right'
                this.md_comment = this.md_comment == 6 ? 12 : 6
                this.comment_order = true
            }
        },
        collapseOrder(){
            if (this.icon_order == 'mdi-arrow-collapse-left') {
                this.icon_order = 'mdi-arrow-collapse-right'
                this.md_order = this.md_order == 6 ? 12 : 6
                this.comment_show = false
            } else{
                this.icon_order = 'mdi-arrow-collapse-left'
                this.md_order = this.md_order == 6 ? 12 : 6
                this.comment_show = true
            }
        },
        clearFilter(){
            this.card_number = ''
            this.free_text = ''
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.cards = []
            this.getCardNumber(1, 10)
        },
        async getCardNumber(page = 1, itemsPerPage = 10){
            this.loadingDatatable = true
            this.pagination = page
            this.cards = []

            var reqBody = {
                'id': this.card_number ? parseInt(this.card_number) : 0,
                'name': this.free_text ? this.free_text : '',
                'sort_key': this.sortName,
                'order' : this.sortBy,
                'page': page,
                'limit': itemsPerPage
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards`, reqBody, false, false, false)

            if (respData.data.error_message === "") {
                this.loadingDatatable = false
                this.totalItems = respData.data.extra
                this.cards = respData.data.value
            }
            
        },
        async showDetail(row){
            console.log(row);
            this.display = 'none'
            this.displayItem = 'block'
            this.detailItem = row
            this.getPatientProfile()
            this.getImages()
            this.getHistoryList()
            this.getDocumentList()
            this.getReservationList()
        },
        async getPatientProfile(){
            var reqBody = {
                'id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/profile`, reqBody, false, false, false)

            this.date_of_birth = respData.data.value.date_of_birth
            this.name = respData.data.value.name
            this.ruby = respData.data.value.rubi
            this.age = respData.data.value.age + '歳'
            this.gender = respData.data.value.gender
            this.mobile = respData.data.value.mobile
            this.address = respData.data.value.prefecture + ' ' + respData.data.value.city + ' ' + respData.data.value.street + ' ' + respData.data.value.building
            // this.history_lists = respData.data.value.history_list
        },
        async getImages(){
            var reqBody = {
                'id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/get_case_images`, reqBody, false, false, false)

            this.images = respData.data.value
        },
        async getHistoryList(){
            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/records`, reqBody, false, false, false)

            this.history_lists = respData.data.value ? respData.data.value : []
            this.comment_text = respData.data.value ? respData.data.value[0].each_card.comment : ''
            this.color_default = respData.data.value ? respData.data.value[0].date : ''
            this.rowDetailHistory = respData.data.value ? respData.data.value[0] : ''
            this.prescriptions = respData.data.value ? respData.data.value[0].each_card.prescriptions : []

        },
        hapus(key){
            this.raw_images.splice(key, 1)
        },
        reset() {
            this.imgSrc = ''
            this.cropImg = ''
		},
        setImage(e) {
            console.log(e.target.files);
            var files = e.target.files

            if (files.length > 1) {
                for (let index = 0; index < files.length; index++) {
                    this.reset()
                    var file = files[index]
                    if (!file.type.includes('image/')) {
                        alert('Please select an image file');
                        return;
                    }
                    if (typeof FileReader === 'function') {
                        var reader = new FileReader()
                        reader.onload = (event) => {
                            this.raw_images.push(event.target.result)   
                            // console.log(event.target.result);
                            // this.imgSrc = event.target.result
                        };
                        reader.readAsDataURL(file)
                    } else {
                        alert('Sorry, FileReader API not supported')
                    }    
                }
                
                this.dialog_crop = false
            } else {
                this.reset()
                var file = files[0]
                if (!file.type.includes('image/')) {
                    alert('Please select an image file');
                    return;
                }
                if (typeof FileReader === 'function') {
                    var reader = new FileReader()
                    reader.onload = (event) => {
                        this.imgSrc = event.target.result
                    };
                    reader.readAsDataURL(file)
                } else {
                    alert('Sorry, FileReader API not supported')
                }    
            }
		},
        saveImage(){
            this.raw_images.push(this.$refs.cropper.getCroppedCanvas().toDataURL())
            this.reset()
            this.dialog_crop = false
        },
        async saveCaseImages(){
            this.dialog_image = false
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'id': this.detailItem ? parseInt(this.detailItem.id) : '',
                'case_images': this.raw_images
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/set_case_images`, reqBody, false, false, false)
            
            this.getImages()
            this.$store.dispatch('setOverlay', false)
            this.raw_images = []
        },
        getFullImage(img, case_images, index){
            console.log(img, case_images);
            setTimeout(() => {
                this.overlay = false
                this.dialog_full_image = true
                this.image_full = img
                this.image_fulls = case_images
                this.image_full_key = parseInt(index)
            }, 500)
        },
        prevImage(key){
            if (key >= 0) {
                this.image_full_key = parseInt(key) - 1
                this.image_full = this.image_fulls[parseInt(this.image_full_key)]
            }
        },
        nextImage(key){
            if (this.image_fulls.length >= key) {
                this.image_full_key = parseInt(key) + 1
                this.image_full = this.image_fulls[parseInt(this.image_full_key)]
            }
        },
        async saveComment(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : '',
                'id': this.rowDetailHistory ? this.rowDetailHistory.id : '',
                'comment' : this.comment_text
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/update_comment`, reqBody, false, false, false)
            
            this.$store.dispatch('setOverlay', false)
            this.snackbar = {
                color: "primary text-white",
                icon: "mdi-checkbox-marked-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Success",
                text: "Successfully",
                visible: true
            };
            this.getHistoryList()
        },
        changeStyle(style) {
            console.log(style);
            var sel = window.getSelection(); // Gets selection
            if (sel.rangeCount) {
                // Creates a new element, and insert the selected text with the chosen style
                var e = document.createElement('span');
                console.log(e.classList);
                e.classList.add(style); // Selected style (class)
                e.innerHTML = sel.toString(); // Selected text

                // https://developer.mozilla.org/en-US/docs/Web/API/Selection/getRangeAt
                var range = sel.getRangeAt(0);
                range.deleteContents(); // Deletes selected text…
                range.insertNode(e); // … and inserts the new element at its place
            }
        },
        rowHistory(row){
            console.log(row);
            this.prescriptions = []
            this.rowDetailHistory = row
            this.comment_text = row.each_card.comment
            this.color_default = row.date
            this.prescriptions = row.each_card.prescriptions
            console.log(this.prescriptions);
        },
        async insertRecord(){
            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/insert_record`, reqBody, false, false, false)

            this.getHistoryList()
            this.dialogRecord = false

        },
        async getTreatment(){
            const respData = await backendAdmin.fetchCore(`/api/v1/sell_entity_treatment`, '', false, false, false)
            this.treatments = respData.data.value
        },
        async getMedicine(){
            const respData = await backendAdmin.fetchCore(`/api/v1/sell_entity_medicine`, '', false, false, false)
            this.medicines = respData.data.value
        },
        async saveProduct(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : '',
                'id': this.rowDetailHistory ? this.rowDetailHistory.id : '',
                'product_name' : this.medicine ? this.medicine.name : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/add_product`, reqBody, false, false, false)
            
            this.$store.dispatch('setOverlay', false)
            this.snackbar = {
                color: "primary text-white",
                icon: "mdi-checkbox-marked-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Success",
                text: "Successfully",
                visible: true
            };
            this.medicine = ''
            this.dialog_medicine = false
            this.getHistoryList()
        },
        async dialogDeletePrescription(index, item){
            this.course = item.course ? item.course : ''
            this.indexCourse = index
        },
        async deletePrescription(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : '',
                'id': this.rowDetailHistory ? this.rowDetailHistory.id : '',
                'index' : this.indexCourse ? this.indexCourse : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/delete_product`, reqBody, false, false, false)
            
            if (respData.data.error_message) {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "red text-white",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data.error_message,
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "primary text-white",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Delete",
                    visible: true
                };
                this.indexCourse = ''
                this.course = ''
                this.dialog_delete_prescription = false
                this.getHistoryList()
            }
        },
        async getItem(){
            const respData = await backendAdmin.fetchCore(`/api/v1/sell_entity_item`, '', false, false, false)
            this.items = respData.data.value
        },
        async getDocumentList(){
            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/document_array`, reqBody, false, false, false)
            this.file_documents = respData.data.value ? respData.data.value : []
        },
        setFileDocument(e) {
            this.file_data = ''
            this.file_name = ''
            var file = e.target.files[0]
            if (typeof FileReader === 'function') {
                var reader = new FileReader()
                reader.onload = (event) => {
                    this.file_name = e.target.files[0].name
                    this.file_data = event.target.result
                };
                reader.readAsDataURL(file)
            } else {
                alert('Sorry, FileReader API not supported')
            }
		},
        async uploadDocument(){
            this.dialog_upload_document = false
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : '',
                'file_name': this.file_name ? this.file_name : '',
                'file_data': this.file_data ? this.file_data : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/upload_document`, reqBody, false, false, false)
            
            this.getDocumentList()
            this.$store.dispatch('setOverlay', false)
            this.file_data = ''
            this.file_name = ''
        },
        async rowDocument(item){
            var reqBody = {
                'id': item.id,
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/download_document`, reqBody, false, false, false)

            var FileData = respData.data.value ? respData.data.value.file_data : ''
            var FileName = respData.data.value ? respData.data.value.file_name : ''

            var file = this.downloadFile(FileData, FileName)

            console.log(file);

            const a = document.createElement("a");
            a.href = URL.createObjectURL(file);
            a.target = '_blank';
            a.click();
        },
        downloadFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], filename, {type:mime});
        },
        dialogDeleteDocument(index, item){
            this.document = item
        },
        async deleteDocument(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : '',
                'id': this.document ? this.document.id : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/cards/delete_document`, reqBody, false, false, false)
            
            if (respData.data.error_message) {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "red text-white",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data.error_message,
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "primary text-white",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Delete",
                    visible: true
                };
                this.document = null
                this.dialog_delete_document = false
                this.getDocumentList()
            }
        },
        async getReservationList(){
            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/reservations_without_datetime`, reqBody, false, false, false)
            this.reservations = respData.data.value ? respData.data.value : []
            console.log(this.reservations);
        }
    },
    watch: {
        // date (val) {
        //     this.dateofBirthday = this.formatDate(this.date)
        // },
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                this.getCardNumber(page, itemsPerPage)
            },
            deep: true,
        },
    },
}
</script>
<style>
.agora-video-player {
height: 100%;
width: 100%;
}
.agora-view {
    display: flex;
    flex-wrap: wrap;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.editable {
  width: 100%;
  height: 680px;
  border: thin solid rgba(143, 143, 143, 0.12);
}

.editable .span-0{ 
  font-weight: normal;  
  text-decoration: none; 
  font-style: normal;   
}

.editable .span-b{
  font-weight: bold;
}

.editable .span-u{
  text-decoration: underline;
}

.editable .span-i{
  font-style: italic;
}
</style>
